import React, { useEffect } from 'react'
import './image-card.styles.scss'

const ImageCard = ({ index, imageData, chooseImage }) => {

    useEffect(() => {
        // console.log(imageData.src)
    }, [])

    return (
        // <div className="image-container" >
        <>    
            {
                (imageData) &&
                <div className="image"  style={{'backgroundImage': `url('${imageData.src}')`}} id={`image-${index}`} onClick={() => chooseImage(index, imageData.id)} >
                    {/* <div className='image'  style={{'backgroundImage': `url('https://firebasestorage.googleapis.com/v0/b/image-comp-app.appspot.com/o/uploaded-images%2F1676349631534-School%20Logo%20(39).png?alt=media&token=39a40c08-4c95-422c-b8bd-565fd8f75893')`}}></div> */}
                    {/* <div className='overlay' id={`overlay-${index}`} ></div> */}
                </div>
            }
        </>
        // </div>
    )
}

export default ImageCard