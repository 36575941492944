import { rtDb } from 'firebase-utils/firebase.utils';
import { ref, increment, update, set, get, child } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import './comparison-container.styles.scss'
import ImageCard from './image-card.component'

const ComparisonContainer = ({ imageSet }) => {

    const [ image1, setImage1 ] = useState(null);
    const [ image2, setImage2 ] = useState(null);
    const [ random, setRandom ] = useState(1);

    useEffect(() => {
        refresh();

        return () => {
            
        }
    }, [])
    
    async function chooseImage(index, imageId) {
        // console.log(index)
        const images = document.getElementsByClassName('image')
        for (let i of images) {
            i.classList.remove('ready')
            i.style.pointerEvents = 'none';
        }
        // console.log(index)
        const href = window.location.pathname
        // console.log(href)
        if (href !== '/') {
            const count = await get(child(ref(rtDb), `stats/${imageSet.id}/${imageId}`)).then(snapshot => {
                return snapshot.exists() ? snapshot.val() : false
            })
            // console.log(count)
            if (count) {
                let updates = {}
                updates[`stats/${imageSet.id}/${imageId}`] = increment(1)
                update(ref(rtDb), updates)
            } else {
                set(ref(rtDb, `stats/${imageSet.id}/${imageId}`), 1)
            }
        }
        
        document.getElementById(`image-${index}`).classList.add('chosen')
        setTimeout(() => {
            refresh(index);
        }, 1000);
    }

    function refresh(index) {
        // console.log('refreshing')
        if (index) {
            document.getElementById(`image-${index}`).classList.remove('chosen')
        }
        const href = window.location.pathname
        // console.log(href)
        if (href === '/') {
            setImage1({'src': `https://picsum.photos/550?random=${random}`})
            setImage2({'src': `https://picsum.photos/550?random=${random + 1}`})
            setRandom(random + 2)
        } else {
            const len = imageSet.images.length;
            const rand1 = Math.floor(Math.random() * len)
            let rand2 = Math.floor(Math.random() * len)
            while (rand1 === rand2) {
                rand2 = Math.floor(Math.random() * len)
            }
            // console.log(imageSet.images[rand1].url)
            setImage1({'src': imageSet.images[rand1].url, 'id': imageSet.images[rand1].id})
            setImage2({'src': imageSet.images[rand2].url, 'id': imageSet.images[rand2].id})
        }
        const images = document.getElementsByClassName('image')
        setTimeout(() => {
            for (let i of images) {
                i.classList.add('ready');
                i.style.pointerEvents = 'auto';
            }
        },700)
    }


    return (
        <div className='comparison-container'>
            {
                (image1) &&
                <ImageCard index={1} imageData={image1} chooseImage={chooseImage} />
            }
            <img src={require('assets/versus.png')} className='vs' />
            {
                (image2) &&    
                <ImageCard index={2} imageData={image2} chooseImage={chooseImage} />
            }
        </div>
    )
}

export default ComparisonContainer
