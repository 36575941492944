import React, { useEffect } from 'react'
import './slide-menu.styles.scss'

import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { SignInWithGoogle } from 'firebase-utils/firebase.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faHome, faImages, faSignIn, faSignOut, faX, faXmark } from '@fortawesome/free-solid-svg-icons';

const SlideMenu = ({ show, setShowSlideMenu }) => {

    const navigate = useNavigate();
	const user = useSelector(state => state.userData.currentUser)

    useEffect(() => {
		const menu = document.getElementsByClassName('slide-menu')[0]
		const body = document.querySelector('body')
		if (show) {
            console.log(menu)
			menu.classList.add('active')
			body.style.overflow = 'hidden';
		} else {
            console.log(menu)
			menu.classList.remove('active')
			body.style.overflow = 'auto';		
		}
	}, [show]);

    return (
        <div className="slide-menu-container">
            <nav className="slide-menu">
                <div className='slide-head'>
                    <img className='logo' src={require("assets/picture.png")} alt='app logo' />
                    <FontAwesomeIcon icon={faXmark} className='close-icon' size='2x' onClick={() => setShowSlideMenu(!show)}/>
                </div>
                <div className="g-space-20"></div>
                {
                    (user) &&							
                    <>	
                        
                        
                        <div className='top-link'>
                            <div className='header-name-icon'>
                                <img src={user.photoURL} className='header-user-icon' referrerPolicy="no-referrer" />
                                <span>{`${user.displayName}`}</span>
                            </div>
                        </div>
                        <hr />
                        <Link to='/'>
                            <div className='top-link'>
                                <FontAwesomeIcon icon={faHome} className='slide-icon' />
                                home
                            </div>
                        </Link>	
                        <br />
                        <Link to='/home'>
                            <div className='top-link'>
                                <FontAwesomeIcon icon={faImages} className='slide-icon' />
                                my sets
                            </div>
                        </Link>	
                        <br />
                        {/* <span className='top-link' onClick={() => signOut()}>sign out</span> */}
                    </>
                }
                {
                    (!user) &&    
                    <>
                        <Link to='#' onClick={() => SignInWithGoogle()}>
                            <div className='top-link'>
                                <FontAwesomeIcon icon={faSignIn} className='slide-icon' /> 
                                sign in
                            </div>
                        </Link>
                        <br />
                    </>
                }
                    <Link to='/'>
                        <div className='top-link' onClick={() => window.open('https://buy.stripe.com/bIY8ysfj88Yy0Wk144', '_blank')}>
                            <FontAwesomeIcon icon={faDollarSign} className='slide-icon' /> 
                            donate
                        </div>
                    </Link>
                    
                
                {
                    (user) &&
                    <Link to='/logout'>
                        <div className='top-link'>
                            <FontAwesomeIcon icon={faSignOut} className='slide-icon' />
                            sign out
                        </div>
                    </Link>	
                }
            
            </nav>
            {/* <button className="menu-toggle">Toggle Menu</button> */}
        </div>
    )
}

export default SlideMenu