import React, { useEffect, useState } from 'react'
import './stats.styles.scss'

import Header from 'components/header/header.component'
import { useNavigate, useParams } from 'react-router-dom'
import { child, get, ref } from 'firebase/database'
import { rtDb } from 'firebase-utils/firebase.utils'

const StatsPage = () => {

    const params = useParams();
    const navigate = useNavigate();
    const [ imageSet, setImageSet ] = useState(null);
    const [ stats, setStats ] = useState(null);
    const [ limit, setLimit ] = useState(0);
    const [ title, setTitle ] = useState(null);
    const [ setId, setSetId ] = useState(null);

    useEffect(() => {
        async function getSetAndStats() {
            // console.log(params.setId)
            const imageSetSnap = await get(child(ref(rtDb), `imageSets/${params.setId}`)).then(snapshot => {
                if (snapshot.exists()) {
                    return snapshot.val()
                } else {
                    return false;
                }
            })
            if (imageSetSnap) {
                setSetId(imageSetSnap.id)
                if (imageSetSnap.top5 === params.listId) {
                    setLimit(5) 
                    setTitle(`${imageSetSnap.title} - Top 5`)
                } else if (imageSetSnap.top10 === params.listId) {
                    setLimit(10)
                    setTitle(`${imageSetSnap.title} - Top 10`)
                } else if (imageSetSnap.all === params.listId) {
                    setLimit(imageSetSnap.images.length)
                    setTitle(`${imageSetSnap.title}`)
                }
                let newObj = {}
                for (let i of imageSetSnap.images) {
                    newObj[i.id] = i.url
                }
                setImageSet(newObj);
            }
            const statSnap = await get(child(ref(rtDb), `stats/${params.setId}`)).then(snapshot => {
                if (snapshot.exists()) {
                    return snapshot.val()
                } else {
                    return false;
                }
            })
            if (statSnap) {
                let newArr = []
                for (let s of Object.keys(statSnap)) {
                    newArr.push({'id': s, 'count': statSnap[s]})
                }
                newArr.sort((a,b) => (a.count < b.count) ? 1 : -1)
                setStats(newArr);
            }
        }
        getSetAndStats();
    }, [])

    return (
        <div className='stats-page'>
            <div className="g-container tight">
                <div className='stats-head'>
                    <h2>{title}</h2>
                    <button className="button-6" onClick={() => navigate(`/set/${setId}`)}>Keep Ranking</button>
                </div>
                <table className='uk-table uk-table-striped uk-table-middle'>
                    <tbody>
                    {
                        (stats && imageSet) &&
                        stats.map((stat, index) => (
                            (index < limit) && 
                            <tr key={stat.id}>
                                <td>
                                    {index + 1}
                                </td>
                                <td data-uk-lightbox>
                                    <a href={imageSet[stat.id]} >
                                        <img src={imageSet[stat.id]} className='stat-image' />
                                    </a>
                                </td>
                                <td>{stat.count}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default StatsPage