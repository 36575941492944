import React, { useEffect, useState } from 'react'
import './header.styles.scss'

import { useSelector } from 'react-redux'

import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import UserDropdown from './user-dropdown.component'
import SlideMenu from './slide-menu.component'
import { SignInWithGoogle } from 'firebase-utils/firebase.utils'

const Header = () => {

	const navigate = useNavigate();
	const user = useSelector(state => state.userData.currentUser)
    const [ showDropdown, setShowDropdown] = useState(false)
    const [ showSlideMenu, setShowSlideMenu ] = useState(false)
	let lastScrollTop = 0;

	useEffect(() => {
		window.addEventListener('scroll', setHeaderLocation);
        // console.log('adding event listener')

        return() => {
            window.removeEventListener('scroll', setHeaderLocation)
            // console.log('removing event listener')
        };
	}, [])

    function setHeaderLocation() {
        const header = document.getElementById("header");
        if (header) {
            const pos = window.pageYOffset
            if (pos > lastScrollTop) {
                header.style.top = "-69px";
            } else {
                header.style.top = "0";
            }
            lastScrollTop = pos;
        }
    }


	return (
		<div className='head-container'>
			<header id="header">
				<div className="header-content">
					<div className='left' onClick={() => navigate('/')}>
						<img className='logo' src={require("assets/picture.png")} alt='app logo' />
                        <h4>image comparison app</h4>
					</div>
					<div className="right">
						<div className="right-content-wide">
							<Link to='/'>
								<div className='top-link' onClick={() => window.open('https://buy.stripe.com/bIY8ysfj88Yy0Wk144', '_blank')}>donate</div>
							</Link>
							{
								(user)
                                ?								
								<>	
									<Link to='/home'>
										<div className='top-link'>my sets</div>
									</Link>	
									<div className="menu_spacer"></div>
									<div className='top-link' onClick={() => setShowDropdown(!showDropdown)}>
                                        <div className='header-name-icon'>
                                            <img src={user.photoURL} className='header-user-icon' referrerPolicy="no-referrer" />
                                            <span className='app-name'>{`${user.displayName}`}</span>
                                        </div>
                                        <UserDropdown show={showDropdown} />
                                    </div>
                                    
									{/* <span className='top-link' onClick={() => signOut()}>sign out</span> */}
								</>
                                :
                                <Link to='#' onClick={() => SignInWithGoogle()}>
                                    <div className='top-link'>sign in</div>
                                </Link>
							}
						</div>
						<div className="right-content-narrow">
							<FontAwesomeIcon icon={faBars} size='xl' onClick={() => setShowSlideMenu(!showSlideMenu)} />
						</div>
					</div>
                    
				</div>
			</header>
			<SlideMenu show={showSlideMenu} setShowSlideMenu={setShowSlideMenu} />
		</div>
	)
}

export default Header