import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  otherData: null,
}

export const otherSlice = createSlice({
  name: 'other',
  initialState,
  reducers: {
    setOtherData: (state, action) => {
      state.otherData = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  setOtherData, 
} = otherSlice.actions

export default otherSlice.reducer