import { configureStore } from '@reduxjs/toolkit';
import { persistCombineReducers } from 'reduxjs-toolkit-persist';
import {
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel2';
import userReducer from './slices/userSlice';
import otherReducer from './slices/otherSlice';
import logger from 'redux-logger'

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,

};

const _persistedReducer = persistCombineReducers(
	persistConfig, 
	{
		userData: userReducer,
		otherData: otherReducer
	}
);

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
	serializableCheck: {
	  ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
	},
  }).concat(logger),
});

export const persistor = persistStore(store);