import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentUser: null,
  userProfile: null,
  imageSets: null,
  currentSet: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload
    },
    setImageSets: (state, action) => {
        state.imageSets = action.payload
    },
    setCurrentSet: (state, action) => {
        state.currentSet = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  setCurrentUser, 
  setUserProfile,
  setImageSets,
  setCurrentSet
} = userSlice.actions

export default userSlice.reducer