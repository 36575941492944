import React, { useEffect, useState } from 'react'
import './set.styles.scss'

import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import Header from 'components/header/header.component'
import { rtDb } from 'firebase-utils/firebase.utils'
import { child, get, ref } from 'firebase/database'
import ComparisonContainer from 'components/comparison/comparison-container.component'
import StatsPage from 'pages/stats/stats.page'
import Footer from 'components/footer/footer.component'

const SetPage = () => {

    const params = useParams();
    const navigate = useNavigate();
    const [ imageSet, setImageSet ] = useState(null);

    useEffect(() => {
        
        async function getImageSet() {
            // console.log(params.setId)
            const imageSetSnap = await get(child(ref(rtDb), `imageSets/${params.setId}`)).then(snapshot => {
                if (snapshot.exists()) {
                    return snapshot.val()
                } else {
                    return false;
                }
            })
            if (imageSetSnap) {
                setImageSet(imageSetSnap);
            }
        }
        getImageSet();
    }, [])

    return (
        <div>
            <div className="content-container">
                <Header />
                <div className="g-space-40"></div>
                <div className="g-container">
                    <Routes>
                        <Route path='' element={
                            (imageSet) &&
                            <div>
                                <ComparisonContainer imageSet={imageSet} />
                                <div className="g-space-40"></div>
                                <div className='buttons'>
                                    <button className="button-6" onClick={() => navigate(`/set/${imageSet.id}/${imageSet.top5}`)}>View Top 5</button>
                                </div>
                            </div>
                        }></Route>
                        <Route path=':listId' element={<StatsPage />}></Route>    
                    </Routes>
                    
                </div>
                <div className="g-space-40"></div>
            </div>
            <Footer />
        </div>
    )
}

export default SetPage