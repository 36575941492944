import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const SetList = () => {

    const imageSets = useSelector(state => state.userData.imageSets)
    const navigate = useNavigate();

    return (
        <div className='set-list'>
            <table className="uk-table uk-table-striped uk-table-responsive uk-table-middle">
                <tbody>
                {
                    (imageSets)
                    ?
                    Object.values(imageSets).sort((a,b) => (a.ts < b.ts) ? 1 : -1)
                    .map(set => (
                        <tr key={set.id}>
                            <td className='uk-table-expand'>{set.title}</td>
                            <td>{`${set.images.length} Images`}</td>
                            <td className='list-buttons'>
                                <div>
                                    <button className="button-6 small" onClick={() => navigate(`/set/${set.id}`)}>Link</button>
                                    <button className="button-6 small" onClick={() => navigate(`/set/${set.id}/${set.top5}`)}>Top 5</button>
                                    <button className="button-6 small" onClick={() => navigate(`/set/${set.id}/${set.top10}`)}>Top 10</button>
                                    <button className="button-6 small" onClick={() => navigate(`/set/${set.id}/${set.all}`)}>Full List</button>
                                </div>
                            </td>
                        </tr>
                    ))
                    :
                    'No sets created...'
                }
                </tbody>
            </table>
            
        </div>
    )
}

export default SetList