import React, { useState } from 'react'
import './new-image-set.styles.scss'

import { useNavigate } from 'react-router-dom'
import UploadForm from './images/upload-form.component';
import { getStorage, ref as storageRef, uploadBytes, uploadString, getDownloadURL } from 'firebase/storage'
import { getDatabase, ref as dbRef, set } from "firebase/database"
import UIkit from 'uikit';
import { useDispatch, useSelector } from 'react-redux';
import Resizer from "react-image-file-resizer";
import { RandomString } from 'utils/randomString';
import { setImageSets } from 'state/slices/userSlice';

const NewImageSet = () => {

    const currentUser = useSelector(state => state.userData.currentUser)
    const imageSets = useSelector(state => state.userData.imageSets)
    const [ imagesToUpload, setImagesToUpload ] = useState([]);
    const [ title, setTitle ] = useState(null)
    const navigate = useNavigate();
    const storage = getStorage();
    const dispatch = useDispatch();

    async function getUploads() {
		const files = document.getElementById('fileUpload').files;
		let fileArr = []
		for (let f of files) {
            // console.log(f.type)
			const newFile = window.URL.createObjectURL(f)
            const resizedFile = await resizeFile(f)
			const imageObj = {'name': f.name, 'url': newFile, 'file': resizedFile}
			fileArr.push(imageObj)
			console.log(imageObj)
		}
		dispatch(setImagesToUpload([...imagesToUpload, ...fileArr]))
	}

    const resizeFile = (file) => new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            500,
            500,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "blob"
        );
    });

    async function startUpload() {
		const now = new Date().getTime();
		let urlArr = []
		console.log(imagesToUpload)
		for (let file of imagesToUpload) {
			const fileRef = storageRef(storage, `uploaded-images/${now}-${file.name}`)
			
			const imageObj = await uploadBytes(fileRef, file.file).then(async (snapshot) => {
                const newObj = await getDownloadURL(fileRef)
                .then(async url => {
                    // console.log(url)
                    const obj = {
                        'name': file.name,
                        'url': url,
                        'id': RandomString(10),
                        'ts': new Date().getTime()
                    }
                    return obj;
                }).catch(err => console.log(err))
                return newObj;
			}).catch(err => console.log(err))
            console.log(imageObj)
            urlArr.push(imageObj)
		}
		finalizeUpload(urlArr, now)
	}

	async function finalizeUpload(urlArr, now) {
        console.log(urlArr)
        const obj = {
            'id': RandomString(15),
            'top5': RandomString(15),
            'top10': RandomString(15),
            'all': RandomString(15),
            'creator': currentUser.appId,
            'images': urlArr,
            'title': title,
            'ts': now
        }
		const db = getDatabase();
		await set(dbRef(db, `imageSets/${obj.id}/`), obj)
        for (let a of urlArr) {
            await set(dbRef(db, `allImages/${a.id}-${a.ts}/`), {'id': a.id, 'url': a.url})
        }
		UIkit.modal.alert('Upload complete!');
        dispatch(setImageSets({...imageSets, ...{[obj.id]: obj}}));
		setTitle(null);
        setImagesToUpload([]);
	}

    return (
        <div className='new-image-set'>
            <div className="g-container tight">
                <div>
                    <button className="button-6" onClick={() => navigate('/home')}>back</button>
                </div>
                <div className="g-space-20"></div>
                <h3>New Image Set</h3><hr />
                <div className="g-space-20"></div>
                <div className="field">
                    <label>Name this image set</label>
                    <input type='text' id='set-title' defaultValue={title ? title : ''} onChange={(e) => setTitle(e.target.value)}/>
                </div>
                                    
                {/* <UploadedImages uploadedImages={[]} /> */}
                {/* <UploadForm imagesToUpload={imagesToUpload} setImagesToUpload={setImagesToUpload} /> */}

                <div className="upload-form">
                    <p className='title'>
                        Upload new images
                    </p>
                    <div className='upload-btn-wrapper'>
                        <button className='button-6'>Choose Images</button>
                        <input id='fileUpload' type='file' multiple
                            accept='image/png' onChange={getUploads}
                        />
                    </div>
                    <div className='thumbs'>
                        {
                            imagesToUpload.map(image => (
                                <span key={image.url}><img src={image.url} className='upload-thumb' /></span>
                            ))
                        }
                    </div>
                </div>
                <hr />
                <div className='buttons'>
                {
                    (imagesToUpload && imagesToUpload.length > 0 && title) &&
                    <button className='button-6' onClick={() => startUpload()}>Create Image Set</button>
                }
                </div>
            </div>
        </div>
    )
}

export default NewImageSet