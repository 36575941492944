import { signOut } from 'firebase-utils/firebase.utils';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentUser, setImageSets, setUserProfile, setCurrentSet } from 'state/slices/userSlice';

const LogoutPage = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        async function removeUser() {
            
            dispatch(setCurrentUser(null));
            dispatch(setUserProfile(null));
            dispatch(setImageSets(null));
            dispatch(setCurrentSet(null));
            await signOut();
            navigate('/')
        }
        removeUser();

    }, [])

    return (
        <div></div>
    )
}

export default LogoutPage