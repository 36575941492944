import { ref, query, orderByChild, equalTo, get, set } from "firebase/database";
import { rtDb } from "firebase-utils/firebase.utils";
import { RandomString } from "./randomString";

export default async function getUserData(user) {
    console.log(user)
    const userSnap = await get(query(ref(rtDb, 'users'), orderByChild('email'), equalTo(user.email))).then(snapshot => {
        return snapshot.exists() ? snapshot.val() : false;
    })
    
    console.log(userSnap);
    if (!userSnap) {
        user.appId = RandomString(20);
        await set(ref(rtDb, `users/${user.appId}`), user);
        return {
            'userData': user,
            'imageSets': {}
        };
    }
    let userData = {}
    Object.values(userSnap).forEach(user => {
        userData = user;
    })

    const setSnap = await get(query(ref(rtDb, 'imageSets'), orderByChild('creator'), equalTo(userData.appId))).then(snapshot => {
        return snapshot.exists() ? snapshot.val() : false;
    })

    return {
        'userData': userData,
        'imageSets': setSnap
    };
}