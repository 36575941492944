import ComparisonContainer from 'components/comparison/comparison-container.component'
import Footer from 'components/footer/footer.component'
import Header from 'components/header/header.component'
import { SignInWithGoogle } from 'firebase-utils/firebase.utils'
import React from 'react'
import { useSelector } from 'react-redux'
import './landing.styles.scss'

const LandingPage = () => {

    const currentUser = useSelector(state => state.userData.currentUser)

    return (
        <div className='landing-page'>
            <div className="content-container">
                <Header />
                <div className="g-space-40"></div>
                <div className="g-container">

                    <ComparisonContainer />
                    
                    <div className="g-space-40"></div>
                    <div className='right-col'>
                        <h2>image comparison app</h2>
                        {
                            (!currentUser) &&
                            <button className="button-6" onClick={() => SignInWithGoogle()}>sign in</button>
                        }
                    </div>
                    
                </div>
                <div className="g-space-40"></div>
            </div>
            <Footer />
        </div>
    )
}


export default LandingPage