import React from 'react'
import './set-list-container.styles.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import SetList from 'components/set-list/set-list.component'
import { useNavigate } from 'react-router-dom'

const SetListContainer = () => {

    const navigate = useNavigate();

    return (
        <div className="set-list-container">
            <div className="g-container tight">
                <div className='list-header'>
                    <h3>My Sets</h3>
                    <button className="button-6" onClick={() => navigate('newset')} >
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
                <hr />
                <SetList />
            </div>
        </div>
    )
}

export default SetListContainer;