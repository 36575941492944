import React from 'react'
import './home.styles.scss'

import Header from 'components/header/header.component'
import { Route, Routes } from 'react-router-dom'
import SetListContainer from 'components/set-list/set-list-container.component'
import NewImageSet from 'components/set-list/new-image-set.component'
import Footer from 'components/footer/footer.component'

const HomePage = () => {

    return (
        <div className='home-page'>
            <div className="content-container">
                <Header />
                <div className="g-space-40"></div>
                <div className="content-container">
                    <div className="g-container">
                        <Routes>
                            <Route path='' element={<SetListContainer />}></Route>
                            <Route path='newset' element={<NewImageSet />}></Route>
                        </Routes>
                    </div>  
                </div>
                <div className="g-space-40"></div>
            </div>
            <Footer />
        </div>
    )
}

export default HomePage