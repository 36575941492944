import React, { useEffect } from 'react';
import LandingPage from 'pages/landing/landing.page';

import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser, setImageSets } from 'state/slices/userSlice';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'firebase-utils/firebase.utils';
import HomePage from 'pages/home/home.page';
import LogoutPage from 'pages/logout/logout.page';
import getUserData from 'utils/getUserData';
import SetPage from 'pages/set-page/set.page';
import StatsPage from 'pages/stats/stats.page';

function App() {

    const currentUser = useSelector(state => state.userData.currentUser)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const href = window.location.href
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // console.log(user)
                if (!currentUser) {
                    const newUser = {
                        displayName: user.displayName,
                        email: user.email,
                        uid: user.uid,
                        photoURL: user.photoURL,
                        provider: user.providerData[0].providerId
                    }
                    const userData = await getUserData(newUser);
                    dispatch(setCurrentUser(userData.userData));
                    dispatch(setImageSets(userData.imageSets));
                }
                // navigate('/home')
            } else {
                if (currentUser) {
                    dispatch(setCurrentUser(null))
                }
                if (!href.includes('/set')) {
                    navigate('/')
                }
            }
        });
    
      }, [currentUser])

    return (
        <div className="App">
            <Routes>
                <Route path='' element={<LandingPage />}></Route>
                <Route path='home/*' element={<HomePage />}></Route>
                <Route path='set/:setId/*' element={<SetPage />}></Route>
                {/* <Route path='set/:setId/stats/:listId/' element={<StatsPage />}></Route> */}
                <Route path='logout' element={<LogoutPage />}></Route>
            </Routes>
        </div>
    );
}

export default App;
