import React from 'react'
import './footer.styles.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright } from '@fortawesome/free-regular-svg-icons'

const Footer = () => {
  return (
    <footer>
        <div className='footer-content'>
            <FontAwesomeIcon icon={faCopyright} />{" "}
            Copyright {new Date().getFullYear()} Taming The Tech, LLC
            <br />
            <a href="https://tamingthetech2--tamingthetech.repl.co/about.html" target="_blank" className="footer-link" rel="noreferrer">
                About
            </a> | <a href="mailto:adam@tamingthetech.com" target="_blank" className="footer-link" rel="noreferrer">
                Contact
            </a> | <a href="/info/terms.html" target="_blank" className="footer-link" rel="noreferrer">
                Terms of Use
            </a> | <a href="/info/privacy.html" target="_blank" className="footer-link" rel="noreferrer">
                Privacy Policy
            </a>
        </div>
	</footer>
  )
}

export default Footer